import React from "react";
import FooterSecondary from "../Footer/secondary";

export default function LayoutSecondary({
  children,
}: React.PropsWithChildren<{
  children: React.ReactNode;
}>) {
  return (
    <div>
      {children}
      <FooterSecondary />
    </div>
  );
}
