import React from "react";
import BannerImg from "../../images/saaspay-aws/banner.svg";
import EmailIcon from "../../images/saaspay-aws/email-icon.svg";
import AddressIcon from "../../images/saaspay-aws/address-icon.svg";
import Button from "../../atoms/Button";
import { Link } from "gatsby";

const FooterSecondary = ({ footerClassName }: { footerClassName?: string }) => {
  return (
    <div
      className={
        "footer-wrap relative bg-[#280666] overflow-hidden " + footerClassName
      }
    >
      <div className="h-full w-full absolute top-0 -z-10  overflow-hidden">
        <img src={BannerImg} className="w-full h-full object-center" />
      </div>
      <div className="w-full z-10 px-5 xl:px-0 pb-8 xl:pb-4">
        <div className="footer-container max-w-[1280px] mx-auto justify-between">
          <div
            className={
              "flex flex-wrap justify-between items-center mt-14 md:mt-24"
            }
          >
            <div
              className={
                "text-3xl md:text-4xl text-white max-w-screen-md gilroy-regular"
              }
            >
              Browse our exclusive{" "}
              <b className={"gilroy-bold"}>SaaS seller partner offerings</b>,
              and <b className={"gilroy-bold"}>unlock growth</b> with SaaSPay.
            </div>
            <a
              href={"https://form.jotform.com/240982279984475"}
              target={"_blank"}
            >
              <Button
                height={50}
                width={174}
                variant={"secondary"}
                className={"!rounded-full mt-8 md:mt-0"}
                id={"check-now-footer"}
              >
                Learn more
              </Button>
            </a>
          </div>
          <hr className={"mt-9 mb-9 md:mb-20 opacity-20 border-[#EFEFEF]"} />
          <div className="f-top-content text-white">
            {/*<p className="h2 text-[18px] leading-[26px]">Ready to sell SaaS?</p>*/}
            {/*<p className="h2  text-[32px] leading-[32px] sm:text-[42px] sm:leading-[42px] md:text-[52px] md:leading-[52px] lg:text-[62px] lg:leading-[62px] xl:text-[72px] xl:leading-[72px]  tracking-[2px]">*/}
            {/*  <span className="gilroy-light">Find Your</span> Perfect Match*/}
            {/*</p>*/}
            <div className="f-icon-container text-white flex flex-col sm:flex-row gap-4 justify-between mt-4 md:mt-8 mb-5 md:mb-10 max-w-xl">
              <div className="icon-item flex items-center gap-4 max-w-[300px]">
                <img src={AddressIcon} className="w-8 lg:w-12" />
                <p className="open-400-16-28 leading-[20px]">
                  GR Icon, Koramanagala, Bangalore
                </p>
              </div>
              <div
                className="icon-item flex items-center gap-4 max-w-[250px] cursor-pointer"
                onClick={(e) => {
                  window.location.href = "mailto:hello@saaspay.co";
                  e.preventDefault();
                }}
              >
                <img src={EmailIcon} className="w-8 lg:w-12" />
                <p className="open-400-16-28 leading-[20px]">
                  hello@saaspay.com
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8 py-3 md:py-6 lg:py-8 md:grid-cols-3 lg:grid-cols-4">
              <div>
                <h2 className="mb-6 gilroy-bold text-[18px] text-white">
                  SaaSPay
                </h2>
                <ul className="inter-400-14-20 text-white opacity-75">
                  <li className="mb-4">
                    <a
                      href="https://form.jotform.com/240982279984475"
                      target={"_blank"}
                      className="hover:underline"
                    >
                      Talk to our team
                    </a>
                  </li>
                  <li className="mb-4">
                    <Link to="/buyer" className="hover:underline">
                      For our Buyers
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link to="/" className="hover:underline">
                      For our Sellers
                    </Link>
                  </li>
                  <li className="mb-4">
                    <div className="cursor-not-allowed text-gray-400 flex gap-3 items-center">
                      Buying on AWS
                    </div>
                  </li>
                  {/* <li className="mb-4">
                    <a href="#" className="hover:underline">
                      Menu 3
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="#" className="hover:underline">
                      Menu 4
                    </a>
                  </li> */}
                </ul>
              </div>
              <div>
                <h2 className="mb-6 gilroy-bold text-[18px] text-white">
                  Product
                </h2>
                <ul className="inter-400-14-20 text-white opacity-75">
                  <li className="mb-4">
                    <div className="cursor-not-allowed text-gray-400 flex gap-3 items-center">
                      Enterprise
                    </div>
                  </li>
                  <li className="mb-4">
                    <div className="cursor-not-allowed text-gray-400 flex gap-3 items-center">
                      Checkout
                    </div>
                  </li>
                  <li className="mb-4">
                    <div className="cursor-not-allowed text-gray-400 flex gap-3 items-center">
                      Product
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 gilroy-bold text-[18px] text-white">
                  Resources
                </h2>
                <ul className="inter-400-14-20 text-white opacity-75">
                  <li className="mb-4">
                    <a
                      href="https://blog.saaspay.com/"
                      className="hover:underline"
                    >
                      Blogs
                    </a>
                  </li>
                  {/* <li className="mb-4">
                    <Link to="/buyer#case-studies" className="hover:underline">
                      Case Studies
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="md:mt-8 py-3 md:py-10 border-t border-t-[#EFEFEF] border-opacity-20 flex flex-col sm:flex-row items-center justify-between">
            <div className="flex mt-4 space-x-10 sm:justify-center md:mt-0">
              <Link
                to="/terms"
                className=" text-white opacity-75 hover:opacity-100"
              >
                <span className="">Terms</span>
              </Link>
              <Link
                to="/privacy-policy"
                className=" text-white opacity-75 hover:opacity-100"
              >
                <span>Privacy</span>
              </Link>
              {/* <a href="#" className="hover:text-gray-900 text-white opacity-75">
                <span>Cookies</span>
              </a> */}
            </div>
            <div className="flex mt-4 space-x-5 sm:justify-center md:mt-0">
              <a
                href="https://www.linkedin.com/company/saaspay"
                target={"_blank"}
                className="hover:text-gray-900 text-white opacity-75 border border-white border-opacity-50 rounded-full p-3 md:p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path
                    d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                    fill="white"
                  ></path>{" "}
                </svg>
                <span className="sr-only">Facebook page</span>
              </a>

              {/* <a
                href="#"
                className="hover:text-gray-900 text-white opacity-75 border border-white border-opacity-50 rounded-full p-3 md:p-4"
              >
                <svg
                  className="w-3 h-3 md:w-5 md:h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 17"
                >
                  <path
                    fill-rule="evenodd"
                    d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="sr-only">Twitter page</span>
              </a>
              <a
                href="#"
                className="hover:text-gray-900 text-white opacity-75 border border-white border-opacity-50 rounded-full p-3 md:p-4"
              >
                <svg
                  className="w-3 h-3 md:w-5 md:h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 17"
                >
                  <path
                    fill-rule="evenodd"
                    d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="sr-only">Twitter page</span>
              </a>
              <a
                href="#"
                className="hover:text-gray-900 text-white opacity-75 border border-white border-opacity-50 rounded-full p-3 md:p-4"
              >
                <svg
                  className="w-3 h-3 md:w-5 md:h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 17"
                >
                  <path
                    fill-rule="evenodd"
                    d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="sr-only">Twitter page</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSecondary;
