import React from "react";
import Text from "../../atoms/Text";
import FormControl from "../../atoms/FormControlSecondry";

type PROPS_TYPE = {
  name: string;
  control: any;
  label: string;
  type?: string;
  rules?: Object;
  disabled?: boolean;
  prepend?: string;
  options?: { code: string | undefined; name: string }[];
  hidden?: boolean;
  defaultValue?: any;
};

export default function FormGroup(props: PROPS_TYPE) {
  return (
    <div
      className={
        "mb-[7px] md:mb-[10px] sm:mb-[10px] lg:mb-[20px]  w-full " +
        (props.hidden ? "hidden" : "")
      }
    >
      <FormControl
        defaultValue={props.defaultValue}
        options={props.options}
        prepend={props.prepend}
        name={props.name}
        label={props.label}
        disabled={props.disabled}
        control={props.control}
        rules={props.rules}
        type={props.type}
        inputClass=""
      />
    </div>
  );
}
