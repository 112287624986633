import React, { useState } from "react";
import { PageSEO } from "../../atoms/SEO";
import LayoutSecondary from "../../organisms/Layout/secondary";
import AwsForm from "../../molecules/AwsForm";
import Logo from "../../images/saaspay-aws/saaspay-x-aws-marketplace-logo.svg";
import LogoBlack from "../../images/saaspay-aws/saaspay-x-aws-marketplace-logo-black.svg";
import "../../styles/aws-landing.scss";
import BannerImg from "../../images/saaspay-aws/bannerNew.svg";
import SuccessIcon from "../../images/saaspay-aws/success_icon.svg";
import FailIcon from "../../images/saaspay-aws/fails_icon.svg";
import VideoThumb1 from "../../images/saaspay-aws/Untitled_design_1.json";
import VideoThumb2 from "../../images/saaspay-aws/Untitled_design_2.json";
import VideoThumb3 from "../../images/saaspay-aws/Untitled_design_3.json";
import { useLottie } from "lottie-react";
import Modal from "../../atoms/Modal";

import Text from "../../atoms/Text";
import crossLogo from "../../images/waitlistModal/crossLogo.svg";
import successLogo from "../../images/waitlistModal/successLogo.svg";
import Button from "../../atoms/Button";

const AwsLanding = ({ location }: any) => {
  const videoThumbs = [VideoThumb1, VideoThumb2, VideoThumb3];
  const videoThumbsAnimations: any = [];
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCreditabilityModal, setShowCreditabilityModal] = useState(false);
  const [isCreditApproved, setIsCreditApproved] = useState(false);
  const [userDomain, setUserDomain] = useState("");

  videoThumbs.forEach((thumb) => {
    const { View } = useLottie({
      animationData: thumb,
      loop: true,
    });
    videoThumbsAnimations.push(View);
  });

  const setCreditResponse = (status: boolean, domain: string) => {
    setIsCreditApproved(status);
    setUserDomain(domain);
  };

  return (
    <LayoutSecondary>
      <PageSEO
        path={location.pathname}
        title={
          "SaaSPay Buyer - Access One-Click Credit With Flexible Repayment"
        }
        description={
          "Powering Payments and possibilities. Access one-click credit to buy annual subscriptions, with flexible repayments."
        }
      />
      <div className="hero-wrap bg-[#fff]">
        <div className="hero-container max-w-[1280px] mx-auto py-[80px] sm:py-[100px] md:py-[120px] lg:py-[160px] px-3 xl:px-0">
          <div className="logo-wrap mb-3">
            <img
              src={LogoBlack}
              alt={"caricature"}
              className="w-full max-w-[240px] sm:max-w-[300px] md:max-w-[420px] lg:max-w-[540px] xl:max-w-[657px]"
            />
          </div>
          <div className="headline-wrap -ml-1">
            <h1 className="sr-only">Buy Now Pay 3 Months Later</h1>
            <div className="flex flex-col h1 text-[40px] sm:text-[60px] md:text-[80px] lg:text-[110px] xl:text-[138px] leading-[40px] sm:leading-[60px] md:leading-[80px] lg:leading-[110px] xl:leading-[140px] text-[#323B4B] font-semibold">
              <div className="flex">
                <div className="burst-icon">
                  <p className="z-10">Buy Now</p>
                </div>
              </div>
              <span>
                Pay <span className="text-p1">3 Months</span> Later
              </span>
            </div>
          </div>
          <div className="sub-headline-wrap flex flex-col lg:flex-row lg:items-center gap-8 lg:gap-16 mt-6 md:mt-10 lg:mt-14">
            <div className="cta-wrap max-w-[250px] sm:max-w-[300px] sm:w-full">
              <a
                href="https://calendly.com/mukund-saas"
                target="_blank"
                className=" lg:w-full lg:h-[80px] px-[21px] rounded-[44px] py-[8px] md:py-[14px] bg-p1 text-white hover:bg-[#323B4B] inter-400-20-24 text-[16px] md:text-[20px] lg:text-[24px] text-center flex justify-center items-center"
              >
                <p className="relative">Activate your Cloud</p>
              </a>
            </div>
            <div className="w-[1px] hidden h-[60px] bg-[#4E5D78] lg:flex" />
            <div className="sub-headline-container">
              <h2 className="inter-400-20-24 text-[16px] md:text-[22px] lg:text-[26px] text-[#4E5D78] leading-[22px] lg:leading-[39px] w-[90%]">
                Start using your AWS cloud today & defer payments by 3 months
                free of charge
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="section-wrap h-full flex flex-col py-[40px] lg:py-[60px] xl:py-[80px] bg-[#FAFBFC]">
        <div className="section-container max-w-[1280px] mx-auto mb-6">
          <div className="section-content flex flex-col justify-center items-center z-[2]">
            <p
              className="h2 text-[16px] sm:text-[20px] md:text-[26px] lg:text-[45px] opacity-20 text-[#background: #323B4B;
] font-extrabold"
            >
              How to
            </p>
            <div className="w-[80%] md:w-[70%] lg:w-[60%] mx-auto mb-4">
              <h2 className="h2 text-[#323B4B] text-[22px] sm:text-[26px] md:text-[32px] lg:text-[38px] text-center leading-[24px] sm:leading-[30px] md:leading-[36.64px] lg:leading-[45px]">
                <span>Upload</span> Documents, <span>Unlock </span> Credit-line,{" "}
                <span>Activate</span> Subscription
              </h2>
            </div>
            <div className="w-[80%] mx-auto mb-6 md:mb-10">
              <p className="gilroy-regular text-[16px]  md:text-[18px] lg:text-[22px] leading-[22px] lg:leading-[24px] w-full text-center text-[#323B4B] w-100">
                Simple 3-step process to optimise and manage cash flow & working
                capital cycle
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6 md:gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-3 xl:px-0">
              <div className="flex flex-col">
                <div className="flex w-full justify-center md:justify-start">
                  {videoThumbsAnimations[0]}
                </div>
                <div className="flex flex-col pt-3 items-center md:items-start">
                  <h2 className="h2 text-[#323B4B] text-[26px]">
                    <span className="gilroy-bold">Step 1</span>
                  </h2>
                  <p className="gilroy-regular text-[14px] text-[##4E5D78] text-center md:text-left w-[80%] md:w-full">
                    Simple document upload
                  </p>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex w-full justify-center md:justify-start">
                  {videoThumbsAnimations[1]}
                </div>
                <div className="flex flex-col pt-3 items-center md:items-start">
                  <h2 className="h2 text-[#323B4B] text-[26px]">
                    <span className="gilroy-bold">Step 2</span>
                  </h2>
                  <p className="gilroy-regular text-[14px] text-[##4E5D78] text-center md:text-left w-[80%] md:w-full">
                    Unlock credit-line to buy subscription
                  </p>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex w-full justify-center md:justify-start">
                  {videoThumbsAnimations[2]}
                </div>
                <div className="flex flex-col pt-3 items-center md:items-start">
                  <h2 className="h2 text-[#323B4B] text-[26px]">
                    <span className="gilroy-bold">Step 3</span>
                  </h2>
                  <p className="gilroy-regular text-[14px] text-[##4E5D78] text-center md:text-left w-[80%] md:w-full">
                    Start using your AWS subscription today & pay 3 months later
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-wrap relative bg-[#5622b0] h-[860px] flex flex-col overflow-hidden">
        <div className="w-full z-0 h-full lg:bg-opacity-0">
          <img
            src={BannerImg}
            className="w-full bg-cover opacity-0 lg:opacity-100 lg:block"
          />
        </div>
        <div className="section-container w-full h-full absolute overflow-hidden ">
          <div className="section-container max-w-[1280px] mx-auto h-full flex flex-col md:flex-row justify-between px-3 xl:px-0">
            <div className="flex section-content h-full w-full pr-3 md:w-1/2">
              <div className="lg:w-[60%] h-full flex flex-col gap-4 pt-6 md:pt-[20%]">
                <div className="logo-wrap pt-4 md:pt-8 lg:pt-10">
                  <img
                    src={Logo}
                    alt={"logo"}
                    className="max-w-[220px] sm:max-w-[240px] md:max-w-[320px] lg:max-w-[400px] xl:max-w-[478px]"
                  />
                </div>
                <h3
                  className="gilroy-regular h2 text-[32px] md:text-[42px]
              lg:text-[50px] leading-[32px] md:leading-[42px] lg:leading-[58px] text-white"
                >
                  Activate AWS Cloud with SaaSPay
                </h3>
                <p className="gilroy-light text-white text-[14px]">
                  Unlock your subscription in one-step and start paying 3 months
                  later.
                </p>
              </div>
            </div>
            <div className="section-content w-full md:w-1/2 my-4 md:my-10">
              <div className="flex flex-col h-full justify-center bg-white rounded-[24px] py-8">
                <AwsForm
                  setShowCreditabilityModal={setShowCreditabilityModal}
                  setCreditResponse={setCreditResponse}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className={"!z-[100]"}
        width={"50%"}
        show={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      >
        <div className={"p-11"}>
          <div className={""}>
            <img
              src={crossLogo}
              className={"ml-auto cursor-pointer"}
              onClick={() => setShowSuccessModal(false)}
              alt={"exit"}
            />
          </div>
          <div className={""}>
            <img
              src={successLogo}
              alt={"tick with green background"}
              className={"mx-auto"}
            />
          </div>
          <div className={"mt-4 md:mt-8 text-center"}>
            <Text
              text={"Sit Tight!"}
              textStyle={"h2 font-bold !text-[#170F49]"}
            />
          </div>
          <div className={"mt-2.5 mb-8 text-center"}>
            <Text
              text={
                "The details have been sent to our lending partners. It usually takes 1-3 days for them to get back with an indicative credit line"
              }
              textStyle={"text-base inter-500-32-40 text-[#170F49]"}
            />
          </div>
          <div>
            <Button
              id={"waitlist-modal"}
              variant={"primary"}
              className={"mx-auto !w-full sm:!w-[300px]"}
              onClick={() => setShowSuccessModal(false)}
              height={56}
              width={300}
            >
              Continue to the website
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className={"!z-[100]"}
        width={"70%"}
        show={showCreditabilityModal}
        onClose={() => setShowSuccessModal(false)}
      >
        <div className={"p-11"}>
          <div className={""}>
            <img
              src={crossLogo}
              className={"ml-auto cursor-pointer"}
              onClick={() => setShowCreditabilityModal(false)}
              alt={"exit"}
            />
          </div>
          <div className={""}>
            {isCreditApproved ? (
              <img src={SuccessIcon} className="w-[60%] mx-auto" />
            ) : (
              <img src={FailIcon} className="w-[60%] mx-auto" />
            )}
          </div>
          <div className={"mt-4 md:mt-8 text-center"}>
            {isCreditApproved ? (
              <Text
                text={"🥳 Congratulations!"}
                textStyle={"h3 font-bold !text-[#170F49]"}
              />
            ) : (
              <Text
                text={"😔 Ohh, No!"}
                textStyle={"h3 font-bold !text-[#170F49]"}
              />
            )}
          </div>
          <div className={`mt-2.5 text-center ${isCreditApproved && "mb-8"}`}>
            <Text
              text={userDomain}
              textStyle={"text-base inter-500-32-40 text-[#170F49]"}
            />
            {isCreditApproved ? (
              <span> can be Financed By Saaspay</span>
            ) : (
              <span> can not be Financed By Saaspay</span>
            )}
          </div>
          {isCreditApproved ? (
            <div>
              <Button
                id={"waitlist-modal"}
                variant={"primary"}
                className={"mx-auto !w-full sm:!w-[200px]"}
                onClick={() => {
                  setShowCreditabilityModal(false);
                  setTimeout(() => {
                    setShowSuccessModal(true);
                  }, 330);
                }}
                height={56}
              >
                What's next
              </Button>
            </div>
          ) : (
            <Text
              text={"try after some time "}
              textStyle={"text-[#9BA2AF] text-left inter-200-16-33"}
            />
          )}
        </div>
      </Modal>
    </LayoutSecondary>
  );
};

export default AwsLanding;
