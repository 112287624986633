import React, { useState, useMemo } from "react";
import { useForm, DefaultValues } from "react-hook-form";
import FormGroup from "../FormGroupSecondry";
import Button from "../../atoms/Button";
import Upload from "../../images/saaspay-aws/upload.svg";
import Text from "../../atoms/Text";
import axios from "axios";
import { useDropzone } from "react-dropzone";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^[6-9]\d{9}$/;
const domainRegex = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/;

type FormValues = {
  name: string | null;
  email: string | null;
  phone_number: number | null | string;
  buyer_domain: string | null;
};

const defaultValues: DefaultValues<FormValues> = {
  name: "",
  email: "",
  phone_number: "",
  buyer_domain: "",
};

export default function AWSForm({
  setShowCreditabilityModal,
  setCreditResponse,
}: any) {
  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues,
  });
  const [loading, setLoading] = useState(false);
  const [selectedFile, setFile]: any = useState({
    mis_report: null,
    projection: null,
  });
  const [alertFileType, setAlertFileType] = useState(false);
  const [fileAlert, setFileAlert] = useState({
    mis_report: false,
    projection: false,
  });

  const handleChange = (files: any, id: string) => {
    if (
      files[0].type === "application/pdf" ||
      files[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      files[0].type === "application/msword"
    ) {
      if (id === "mis_report") {
        setFile({ ...selectedFile, mis_report: files[0] });
      } else if (id === "projection") {
        setFile({ ...selectedFile, projection: files[0] });
      }
      setAlertFileType(false);
    } else {
      setAlertFileType(true);
    }
  };

  function onSubmit(contact: any) {
    if (!selectedFile.mis_report) {
      if (selectedFile.projection) {
        setFileAlert({ projection: false, mis_report: true });
      } else {
        setFileAlert({ projection: true, mis_report: true });
      }
      return;
    }
    if (!selectedFile.projection) {
      if (selectedFile.mis_report) {
        setFileAlert({ mis_report: false, projection: true });
      } else {
        setFileAlert({ mis_report: true, projection: true });
      }
      return;
    }

    setLoading(true);

    // Update the formData object
    const formData = new FormData();

    Object.values(selectedFile).forEach((file: any) => {
      formData.append("file", file, file?.name);
    });

    formData.append("name", contact.name);
    formData.append("email", contact.email);
    formData.append("phone_number", contact.phone_number);
    formData.append("buyer_domain", contact.buyer_domain);
    axios
      .post(
        "https://zobqakuyng.execute-api.ap-south-1.amazonaws.com/submitResponse",
        formData
      )
      .then((res) => {
        setFile({
          mis_report: null,
          projection: null,
        });
        setLoading(false);
        reset();
        if (res.data && res.data.success) {
          setCreditResponse(res.data.is_eligible, res.data.buyer_domain);
          setShowCreditabilityModal(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        alert("Something went wrong!");
        console.error(error, error.data);
      });
  }

  const onInputClick = (event: any) => {
    event.target.value = "";
  };

  const ReportsInput = () => {
    const {
      getRootProps,
      getInputProps,
      open,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      onDrop: (files) => {
        handleChange(files, "mis_report");
      },
      noClick: true,
      noKeyboard: true,
      accept: {
        "application/pdf": [],
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [],
      },
    });
    const focusedStyle = {
      background: "#5B2EBC1C",
    };

    const acceptStyle = {
      background: "#5B2EBC1C",
    };

    const rejectStyle = {
      background: "#FFEFEB",
    };
    const style = useMemo(
      () => ({
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <div
        className="flex w-full rounded-[5px] border-2 border-[#D6DDE5] border-dotted mb-4 sm:mb-6"
        {...getRootProps({ style })}
      >
        <div className="flex flex-row w-full align-middle justify-center h-[90px]">
          <div className="flex w-[20%] align-middle justify-center">
            <img
              src={Upload}
              alt={"Upload"}
              className="max-w-[30px] sm:max-w-[46px]"
            />
          </div>
          <div className="flex flex-col w-[80%] justify-center">
            {selectedFile.mis_report?.name ? (
              <h6 className="text-[#5B2EBC] gilroy-bold text-[14px] sm:text-[16px]">
                {selectedFile.mis_report?.name}{" "}
                <span
                  className="text-[#fa9d9d] gilroy-regular cursor-pointer pl-2"
                  onClick={(e) =>
                    setFile({ ...selectedFile, mis_report: null })
                  }
                >
                  remove
                </span>
              </h6>
            ) : (
              <h6 className="text-[#A0AEC0] gilroy-regular text-[12px] sm:text-[14px]">
                Drag & drop files or{" "}
                <span
                  className="text-[#5B2EBC] gilroy-regular cursor-pointer"
                  onClick={() => open()}
                >
                  Click here to upload your MIS report
                </span>
              </h6>
            )}

            <p
              className={`text-[#A0AEC0] gilroy-regular text-[12px] ${
                alertFileType && "text-[#fa9d9d]  gilroy-bold text-[14px]"
              }`}
            >
              Supported formates: PDF, DOC
            </p>
            <input
              type="file"
              name="picture"
              className="sr-only"
              value={selectedFile.mis_report}
              onChange={(event) => handleChange(event, "mis_report")}
              onClick={onInputClick}
              {...getInputProps()}
            />
          </div>
        </div>
      </div>
    );
  };
  const ProjectionsInput = () => {
    const {
      getRootProps,
      getInputProps,
      open,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      onDrop: (files) => {
        handleChange(files, "projection");
      },
      noClick: true,
      noKeyboard: true,
      accept: {
        "application/pdf": [],
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [],
      },
    });
    const focusedStyle = {
      background: "#5B2EBC1C",
    };

    const acceptStyle = {
      background: "#5B2EBC1C",
    };

    const rejectStyle = {
      background: "#FFEFEB",
    };
    const style = useMemo(
      () => ({
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );
    return (
      <div
        className="flex w-full rounded-[5px] border-2 border-[#D6DDE5] border-dotted mb-4 sm:mb-6"
        {...getRootProps({ style })}
      >
        <div className="flex flex-row w-full align-middle justify-center h-[90px]">
          <div className="flex w-[20%] align-middle justify-center">
            <img
              src={Upload}
              alt={"Upload"}
              className="max-w-[30px] sm:max-w-[46px]"
            />
          </div>
          <div className="flex flex-col w-[80%] justify-center">
            {selectedFile.projection?.name ? (
              <h6 className="text-[#5B2EBC] gilroy-bold text-[14px] sm:text-[16px]">
                {selectedFile.projection?.name}{" "}
                <span
                  className="text-[#fa9d9d] gilroy-regular cursor-pointer pl-2"
                  onClick={(e) =>
                    setFile({ ...selectedFile, projection: null })
                  }
                >
                  remove
                </span>
              </h6>
            ) : (
              <h6 className="text-[#A0AEC0] gilroy-regular text-[12px] sm:text-[14px]">
                Drag & drop files or{" "}
                <span
                  className="text-[#5B2EBC] cursor-pointer"
                  onClick={() => open()}
                >
                  Click here to upload your 12 months financial projections
                </span>
              </h6>
            )}

            <p
              className={`text-[#A0AEC0] gilroy-regular text-[12px] ${
                alertFileType && "text-[#fa9d9d]  gilroy-bold text-[14px]"
              }`}
            >
              Supported formates: PDF, DOC
            </p>
            <input
              type="file"
              name="picture"
              className="sr-only"
              value={selectedFile.projection}
              onChange={(event) => handleChange(event, "projection")}
              onClick={onInputClick}
              {...getInputProps()}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <form
      id={"waitlist-form-main"}
      name={"waitlist-form-main"}
      onSubmit={handleSubmit(onSubmit)}
      className={"flex flex-wrap items-center justify-center w-full"}
    >
      <div className="flex flex-col w-[80%]">
        <FormGroup
          name={"name"}
          control={control}
          label={"Name"}
          rules={{ required: true }}
        />
        <FormGroup
          name={"email"}
          control={control}
          label={"Email"}
          rules={{ required: true, pattern: emailRegex }}
        />
        <FormGroup
          type={"number"}
          name={"phone_number"}
          control={control}
          label={"Phone Number"}
          rules={{
            required: true,
            maxLength: 10,
            minLength: 10,
            pattern: phoneRegex,
          }}
        />
        <FormGroup
          name={"buyer_domain"}
          control={control}
          label={"Buyer Domain"}
          rules={{ required: true, pattern: domainRegex }}
        />
        <span className="text-[#A0AEC0] gilroy-regular text-[16px] mb-1">
          MIS Report
        </span>

        <ReportsInput />
        {fileAlert.mis_report && (
          <span className="text-[#fa9d9d] gilroy-regular text-[16px] mt-[-16px] mb-4">
            MIS Report is Mandatory.
          </span>
        )}
        <span className="text-[#A0AEC0] gilroy-regular text-[16px] mb-1">
          12 Months financial Projections
        </span>

        <ProjectionsInput />
        {fileAlert.projection && (
          <span className="text-[#fa9d9d] gilroy-regular text-[16px] mt-[-16px] mb-4">
            12 Months financial Projection is Mandatory.
          </span>
        )}
        <Button
          id={"waitlist-form-button"}
          type={"submit"}
          disabled={loading}
          className={" w-full md:rounded-xl py-[14px] sm:py-[16px]"}
          variant={"primary"}
        >
          <Text
            text={loading ? "Submitting. Hold Tight!" : "Submit"}
            className={"inter-500-16-24 !text-white "}
          />
        </Button>
      </div>
    </form>
  );
}
